import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { BookOrderStatus, EBookOrderStatus } from '@remento/types/book-order';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { isMobileViewport } from '@/hooks/useIsMobileViewport';
import { getBookReorderPreviewPath } from '@/modules/routing';
import { useFilterBookOrdersIds, useProjectBookOrdersQuery } from '@/services/api/book';

import { BookOrderList } from '../components/BookOrderList';

import { BookOrderDetailsContainer } from './BookOrderDetails.container';
import { BookOrderListItemContainer } from './BookOrderList.container';

export interface BookOrderHistoryDialogContainerProps {
  projectId: string;
  onClose: () => void;
}

interface ListDialogState {
  type: 'list';
}

interface DetailsDialogState {
  type: 'details';
  bookOrderId: string;
}

type DialogState = ListDialogState | DetailsDialogState;

const ACTIVE_BOOK_ORDER_STATUSES: (BookOrderStatus | EBookOrderStatus)[] = [
  BookOrderStatus.PROCESSING,
  BookOrderStatus.PRINTING,
  BookOrderStatus.SHIPPED,
  EBookOrderStatus.PROCESSING,
  EBookOrderStatus.EMAILED,
];

export function BookOrderHistoryDialogContainer({ projectId, onClose }: BookOrderHistoryDialogContainerProps) {
  const navigate = useNavigate();

  const projectBookOrdersQuery = useProjectBookOrdersQuery(projectId);
  const bookOrdersIds = useFilterBookOrdersIds(projectBookOrdersQuery.data ?? [], ACTIVE_BOOK_ORDER_STATUSES);

  const [animationDirection, setAnimationDirection] = useState<'ltr' | 'rtl'>('rtl');
  const [dialogState, setDialogState] = useState<DialogState>({ type: 'list' });
  const [reorderComingSoonOpen, setReorderComingSoonOpen] = useState(false);

  const handleDetailsBack = useCallback(() => {
    setAnimationDirection('ltr');
    setTimeout(() => {
      setDialogState({ type: 'list' });
    }, 0);
  }, []);

  const handleViewDetails = useCallback((bookOrderId: string) => {
    setAnimationDirection('rtl');
    setTimeout(() => {
      setDialogState({ type: 'details', bookOrderId });
    }, 0);
  }, []);

  const handleReorder = useCallback(
    (bookOrderId: string) => {
      if (isMobileViewport()) {
        setReorderComingSoonOpen(true);
        return;
      }

      navigate(getBookReorderPreviewPath(projectId, bookOrderId));
    },
    [navigate, projectId],
  );

  return (
    <RMDialog.Root open variant="full-screen" onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={dialogState.type === 'list' ? 'Order history' : 'Order details'}
          centerTitle
          leftAdornment={
            dialogState.type === 'details' ? (
              <RMIconButton
                icon={faChevronLeft}
                tooltip={null}
                backgroundColor="transparent"
                stateColor="darken-neutral"
                onClick={handleDetailsBack}
              />
            ) : null
          }
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Body>
          <RMDialog.Transition key={dialogState.type} animation={animationDirection}>
            {dialogState.type === 'list' && (
              <BookOrderList.Root>
                {bookOrdersIds.map((id) => (
                  <BookOrderListItemContainer
                    key={id}
                    bookOrderId={id}
                    onReorder={() => handleReorder(id)}
                    onViewDetails={() => handleViewDetails(id)}
                  />
                ))}
              </BookOrderList.Root>
            )}
            {dialogState.type === 'details' && (
              <BookOrderDetailsContainer
                bookOrderId={dialogState.bookOrderId}
                onReorder={() => handleReorder(dialogState.bookOrderId)}
              />
            )}
          </RMDialog.Transition>
        </RMDialog.Body>
      </RMDialog.Content>

      {/* Mobile reorder coming soon dialog */}
      <RMDialog.Root open={reorderComingSoonOpen} onClose={() => setReorderComingSoonOpen(false)}>
        <RMDialog.Content>
          <RMDialog.Header
            title="Coming soon to this device!"
            message="You can reorder this book on a laptop or desktop computer."
            rightAdornment={<RMCloseButton onClick={() => setReorderComingSoonOpen(false)} />}
          />
          <RMDialog.Footer>
            <RMButton background="primary" fullWidth onClick={() => setReorderComingSoonOpen(false)}>
              Got it
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </RMDialog.Root>
  );
}
