import { BookColor, BookShippingAddress } from '@remento/types/book';
import { BookOrderStatus } from '@remento/types/book-order';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMGrid } from '@/components/RMGrid';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { BookCoverPreview } from '../BookCoverPreview/BookCoverPreview';
import { BookPreviewScaler } from '../BookPreviewScaler/BookPreviewScaler';

import {
  BookPreviewWrapper,
  ClickableText,
  DetailItem,
  OrderNumberText,
  Separator,
  StyledBookOrderDetails,
  TopPanel,
  TopPanelContent,
} from './BookOrderDetails.styles';

const BOOK_ORDER_STATUS_LABEL: Record<BookOrderStatus, string> = {
  [BookOrderStatus.AWAITING_PAYMENT]: 'Awaiting payment',
  [BookOrderStatus.PROCESSING]: 'Printing in progress',
  [BookOrderStatus.PRINTING]: 'Printing',
  [BookOrderStatus.SHIPPED]: 'In transit',
  [BookOrderStatus.CANCELED]: 'Canceled',
};

export interface BookOrderDetailsProps {
  orderId: string;
  title: string;
  subtitle: string | null;
  coverUrl: string | null;
  color: BookColor;
  purchaserName: string;
  creatorName: string;
  purchaseDate: number;
  status: BookOrderStatus;
  quantity: number;
  creditsUsed: number;
  shippingAddress: BookShippingAddress;
  trackingNumber: string | null;
  onReorder: () => void;
  onOpenTracking: () => void;
  onContactSupport: () => void;
}

export function BookOrderDetails({
  orderId,
  title,
  subtitle,
  coverUrl,
  color,
  purchaserName,
  creatorName,
  purchaseDate,
  status,
  quantity,
  creditsUsed,
  shippingAddress,
  trackingNumber,
  onReorder,
  onOpenTracking,
  onContactSupport,
}: BookOrderDetailsProps) {
  const isMobile = useIsMobileViewport();

  return (
    <StyledBookOrderDetails>
      <TopPanel>
        <BookPreviewWrapper>
          <BookPreviewScaler height={isMobile ? 110 : 300}>
            <BookCoverPreview
              title={title}
              subtitle={subtitle}
              color={color}
              photoUrl={coverUrl}
              hideSideCover
              padding={false}
            />
          </BookPreviewScaler>
        </BookPreviewWrapper>
        <TopPanelContent>
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" bold>
            {title}
          </RMText>

          {isMobile == false && <RMSpacer direction="column" spacing="2xs" />}

          <RMText type="sans" size="xs" color="on-surface-primary">
            Sent to {shippingAddress.recipientName} · Created by {creatorName}
          </RMText>
          <RMText type="sans" size="xs" color="on-surface-primary">
            {dayjs(purchaseDate).format('MMMM DD, YYYY')}
          </RMText>
          <RMText type="sans" size="xs" color="on-surface-primary">
            Status: {BOOK_ORDER_STATUS_LABEL[status]}
          </RMText>
          <RMText type="sans" size="xs" color="on-surface-primary">
            Copies: {quantity}
            {creditsUsed > 0 ? ` (${creditsUsed} credit${creditsUsed > 1 ? 's' : ''} redeemed)` : ''}
          </RMText>

          {isMobile == false && (
            <>
              <RMSpacer direction="column" spacing="sm" />
              <RMButton background="primary" autoLoading fullWidth onClick={onReorder}>
                Reorder
              </RMButton>
            </>
          )}
        </TopPanelContent>
      </TopPanel>

      {isMobile && (
        <RMButton background="primary" autoLoading fullWidth onClick={onReorder}>
          Reorder
        </RMButton>
      )}

      <Separator />

      <RMGrid.Root spacing="md">
        {/* Shipping */}
        <RMGrid.Item columns={isMobile ? 12 : 4}>
          <DetailItem>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              Shipping information
            </RMText>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              {shippingAddress.recipientName}
              <br />
              {shippingAddress.addressLine1}
              {shippingAddress?.addressLine2 && ' ' + shippingAddress?.addressLine2}
              <br />
              {shippingAddress?.city}&nbsp; {shippingAddress?.state},&nbsp;
              {shippingAddress?.zipCode}
              <br />
              {shippingAddress.country}
            </RMText>
          </DetailItem>
        </RMGrid.Item>
        {/* Order number */}
        <RMGrid.Item columns={isMobile ? 12 : 4}>
          <DetailItem>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              Order number
            </RMText>
            <OrderNumberText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              {orderId}
            </OrderNumberText>
          </DetailItem>
        </RMGrid.Item>
        {/* Credits */}
        {creditsUsed > 0 && (
          <RMGrid.Item columns={isMobile ? 12 : 4}>
            <DetailItem>
              <RMText type="sans" size="s" color="on-surface-primary" bold>
                Credits redeemed
              </RMText>
              <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
                {creditsUsed} credit{creditsUsed > 1 ? 's' : ''} redeemed
              </RMText>
            </DetailItem>
          </RMGrid.Item>
        )}
        {/* Ordered by */}
        <RMGrid.Item columns={isMobile ? 12 : 4}>
          <DetailItem>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              Ordered by
            </RMText>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              {purchaserName}
            </RMText>
          </DetailItem>
        </RMGrid.Item>
        {/* Tracking number */}
        <RMGrid.Item columns={isMobile ? 12 : 4}>
          <DetailItem>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              Tracking number
            </RMText>
            <ClickableText
              type="sans"
              size={isMobile ? 'xs' : 's'}
              color={trackingNumber != null ? 'primary' : 'on-surface-primary'}
              underline={trackingNumber != null}
              onClick={onOpenTracking}
            >
              {trackingNumber != null ? trackingNumber : 'Coming soon'}
            </ClickableText>
          </DetailItem>
        </RMGrid.Item>
        {/* Support */}
        <RMGrid.Item columns={isMobile ? 12 : 4}>
          <DetailItem>
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              Need help?
            </RMText>
            <ClickableText
              type="sans"
              size={isMobile ? 'xs' : 's'}
              color="primary"
              underline
              onClick={onContactSupport}
            >
              Contact support
            </ClickableText>
          </DetailItem>
        </RMGrid.Item>
      </RMGrid.Root>
    </StyledBookOrderDetails>
  );
}
