import { TrackingProvider } from '@remento/types/book-order';

export function getShippingUrl(trackingNumber: string, provider: TrackingProvider) {
  switch (provider) {
    case TrackingProvider.FEDEX: {
      return `https://www.fedex.com/wtrk/track/?trknbr=${trackingNumber}`;
    }
    case TrackingProvider.UPS: {
      return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
    }
  }
}
